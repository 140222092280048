/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
        $('.dropdown-toggle').on("click", function(e) {
          $(this).next('ul').toggle();
          e.stopPropagation();
          e.preventDefault();
        });
        $(".dropdown-toggle").dropdown(); //for global/apex menus to expand without scrollbar

        $("#menu-help-guide li.active").parents("ul").addClass("open");

        var activeItem = 'menu-' + local.slug;
        console.log(activeItem);
        $("#menu-help-guide ." + activeItem).addClass("active-item");

        $('.toggle-sidenav').on('click', function() {
          if ($('.container-flex').hasClass('aside-collapse')) {
            $('.container-flex').removeClass('aside-collapse');
          } else {
            $('.container-flex').addClass('aside-collapse');
          }
        });

        $(window).load(function() {
          if ($(window).scrollTop() == 0) {
            $('#sidenavToggle').addClass('topOfPage');
          } else {
            $('#sidenavToggle').removeClass('topOfPage');
          }
        });

        $(window).scroll(function() {
          if ($(window).scrollTop() == 0) {
            $('#sidenavToggle').addClass('topOfPage');
          } else {
            $('#sidenavToggle').removeClass('topOfPage');
          }
        });

        // won't work because of it fires once at the beginning and stops there after 
        /*
        window.setInterval(function() {
              var elem = document.getElementById('sidenavToggle');
              var elem2 = document.getElementsByClassName('container-fluid');
              if (elem.classList.contains('topOfPage') && elem2.classList.contains('aside-collapse')) {
                  elem.style.width = "100px";
                  elem.style.backgroundColor = "red";
                  elem.style.position = "initial";
                  console.log("seen");
                }
              }, 1000);
              */
        function checkForChanges() {
          if ($('.container-flex').hasClass('row')){
            //$('.topOfPage').css('background-color', 'red');
            alert("row");
          }
          else{
            setTimeout(checkForChanges, 500);
          console.log("checking");
        }
        }


        $(".container-fluid").change(function() {
          alert("Handler for .change() called.");
        });


        /*
                $("#sidenavToggle").on("topOfPage", function() {
                  alert("Changed");
                });
                (function() {
                  $("#sidenavToggle").addClass("test").trigger("topOfPage");
                });

        */


      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
